<template>
  <el-card class="box-card" :style=" userreg ? ' padding: .7rem .76rem .44rem .8rem;' : ' padding: .3rem .6rem;' ">
    <div v-show="userreg" class="userreg">
      <div class="title">
        <p class="usertitle" v-if="dataId">{{$t("lang.Bindaccount")}}</p>
        <p class="usertitle" v-else>{{$t("lang.Accountregistration")}}</p>
        <div class="line"></div>
        <div v-if="herderurl">
					<img @click="changeReg" class="erweima" src="@/assets/imgs/erweima.png" v-if="!dataId"/>
					<div class="dlbj" v-if="!dataId">
					  <p>{{$t("lang.Codescanningregistration")}}</p>
					</div>
				</div>
      </div>
      <div class="pasmsg">
        <el-form :model="regFrom" :rules="rules" ref="ruleForm">
          <div class="userinput">
            <el-form-item prop="useremail" class="inputcontent">
              <el-input v-model="regFrom.useremail" :placeholder='$t("lang.Pleaseinputemailss")'></el-input>
              <img class="emailimg" :src="require('@/assets/imgs/youxiang.png')"/>
            </el-form-item>
            <el-form-item prop="verificationval" class="inputcontent veriCode">
              <el-input v-model="regFrom.verificationval" :placeholder='$t("lang.Pleaseentertheverificationcode")'></el-input>
              <div class="sendmsg" @click="sendmsg">
                <p v-show="msgtime">{{$t("lang.Sendverificationcode")}}</p>
                <p v-show="!msgtime">{{ count }}S</p>
              </div>
            </el-form-item>
            <el-form-item prop="newpasval" class="inputcontent">
              <img :src="require('@/assets/imgs/mima.png')" />
              <el-input v-model="regFrom.newpasval" 
							 :placeholder='$t("lang.Pleaseenternumbers")' :type="pwdInputType" maxlength="16" :readonly='so' @focus="sd"></el-input>
							<img class="eyes-icon" :src="eyeIcon" @click="handleShowPwd(1)" style="cursor: pointer"/>
            </el-form-item>
            <el-form-item prop="conpasval" class="inputcontent" v-if="inpus">
              <img :src="require('@/assets/imgs/mima.png')" />
              <el-input v-model="regFrom.conpasval" 
							 :placeholder='$t("lang.Pleaseenternumbersb")' :type="pwdInputTypes" maxlength="16" :readonly='so' @focus="sd"></el-input>
							 <img class="eyes-icon" :src="eyeIcons" @click="handleShowPwd(2)" style="cursor: pointer"/>
            </el-form-item>
          </div>
          <div class="haveuser">
            <p class="existuser">
              {{$t("lang.Existingaccount")}},<router-link to="/">{{$t("lang.Loginnow")}}</router-link>
            </p>
          </div>
          <div class="backlogins">
            <div @click.stop="emaillogin('ruleForm')" class="button">{{$t("lang.determine")}}</div>
          </div>
        </el-form>
      </div>
    </div>
    <div v-show="phonereg" class="weixinreg">
      <div class="title">
        <p class="usertitle">{{$t("lang.Wechatregistration")}}</p>
        <div class="lines"></div>
      </div>
      <div class="pasmsg" id="weixin">
        <div>
          <div class="wechat">
            <wxlogin
              style="display: flex; justify-content: center"
              appid="wx90eff9300833a5a0"
              :scope="'snsapi_login'"
              :theme="'black'"
              :redirect_uri="'https://cloud.univiso.tv'"
              href="data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKIGRpc3BsYXk6IG5vbmU7Cn0="
            >
            </wxlogin>
          </div>
          <div class="wechatuser">
            <p class="existuser">
              {{$t("lang.Existingaccount")}},<router-link to="/">{{$t("lang.determine")}}</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
import * as userReg from "@/api/apis.js";
import JSEncrypt from "jsencrypt";
import wxlogin from "vue-wxlogin";
import { isMobile,isemail } from '@/utils/time'
import * as loing from "@/api/apis.js";
import bus from "@/utils/bus.js";
export default {
  components: { wxlogin },
  data() {
    var emails = (rule, value, callback) => {
      const reg=new RegExp("(^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$)|(^0?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$)");
      if (value == "" || value == undefined || value == null) {
        callback(new Error(this.$t('lang.Pleaseinputemailss')));
      } else {
        if (!reg.test(value)) {
          callback(new Error(this.$t('lang.Pleaseinputemailssa')));
        } else {
          callback();
        }
      }
    };
    var checkMaxVal = (rule, value, callback) => {
      const regs = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
      if (value == "" || value == undefined || value == null) {
        callback(new Error(this.$t('lang.Pleaseenternumbers')));
      } else {
        if (!regs.test(value)) {
          callback(new Error(this.$t('lang.defPassword')));
        } else {
          callback();
        }
      }
    };
    var conPasVal = (rule, value, callback) => {
      if (value == "" || value == undefined || value == null) {
        callback(new Error(this.$t('lang.Pleaseentertheconfirmationpassword')));
      } else if (this.regFrom.newpasval === value) {
        callback();
      } else {
        callback(new Error(this.$t('lang.Pleaseentertheconfirmationpassworda')));
      }
    };
		let ficationval= (rule, value, callback) => {
      if (value == "" || value == undefined || value == null) {
        callback(new Error(this.$t('lang.Pleaseentertheverificationcode')));
      } else {
        callback();
      }
    };
    return {
      userreg: true,
      phonereg: false,
      wxemailval: "",
      oldvalue: "",
      newvalue: "",
      rules: {
        useremail: [
          {
            required: true,
            validator: emails,
            trigger: "blur",
          },
        ],
        verificationval: [
          {
            required: true,
            validator: ficationval,
            trigger: "blur",
          },
        ],
        newpasval: [
          {
            required: true,
            validator: checkMaxVal,
            trigger: "blur",
          },
        ],
        conpasval: [
          {
            required: true,
            validator: conPasVal,
            trigger: "blur",
          },
        ],
      },
      regFrom: {
        useremail: "",
        newpasval: "",
        verificationval: "",
        conpasval: "",
      },
      msgtime: true,
      count: "",
      timer: null,
      dataId: this.$route.query.data,
      isRegister:false,
			so:true,
			inpus:true,
			herderurl:true,
			eyeIcon:require("@/assets/imgs/close_eyes.png"),
			eyeIcons:require("@/assets/imgs/close_eyes.png"),
			pwdInputType:'password',
			pwdInputTypes:'password',
    };
  },
  created() {
		if(window.location.hostname=='ind.univiso.tv'){
			this.herderurl=false
		}else{
			this.herderurl=true
		}
		this.regFrom.useremail = "";
		this.regFrom.newpasval = "";
		this.regFrom.verificationval = "";
		this.regFrom.conpasval = "";
    if (this.$route.query.data) {
      this.dataId === this.$route.query.data;
    }
		let sesw=setInterval(()=>{
			if(sessionStorage.getItem('language')){
				clearInterval(sesw)
			  if (sessionStorage.getItem('language') == "En") {
			    this.$i18n.locale = "en";
			  } else {
			    this.$i18n.locale = "cn";
			  }
			}
		},100)
  },
  methods: {
	  sd(){
		this.so=false  
	  },
    // 验证邮箱是否注册绑定
    emailApi() {
      let emailval = {
        email: this.regFrom.useremail,
      };
      userReg.emailApi(emailval).then((res) => {
        //console.log(res,)
        this.isRegister = res.data
      });
    },
    changeReg() {
      this.userreg = false;
      this.phonereg = true;
    },
    emaillogin(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          const jse = new JSEncrypt();
          const publiceKey =
            "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDXYI847fATDgZY6gFvGzaR7UXN9c007DEb6M6DvbSE5F3+apLG+PqujLIa8YzvSGntT3YTpN/RNQxo4I7NWIIbHC5u4zobDW1XNJOun5NUgnLglJxI5LrFIo4VzRA8x3TPh4Eb2fuc4eSO3j5XzL2OY8h+H1Npz4JwOdj7Okoc4QIDAQAB";
          jse.setPublicKey(publiceKey);
          if (this.dataId) {
            let emailval = {
              email: this.regFrom.useremail,
              password: jse.encrypt(this.regFrom.newpasval),
              passwordqr: jse.encrypt(this.regFrom.conpasval),
              code: this.regFrom.verificationval,
              userid: this.dataId,
            };
            userReg.bindWxLogin(emailval).then((res) => {
              this.$message.success(this.$t('lang.bindingsa'));
              let that = this;
              setTimeout(() => {
                sessionStorage.setItem("token", JSON.stringify(res.data.token));
                loing.userId({ email: res.data.email }).then((response) => {
                  sessionStorage.setItem("user", JSON.stringify(response.data));
                  if (response.data.type === 1) {
                    that.$router.push("nav/user");
                  } else {
                    that.$router.push("nav/manage");
                  }
                });
              }, 1000);
            });
          } else {
            let emailval = {
              email: this.regFrom.useremail,
              password: jse.encrypt(this.regFrom.newpasval),
              passwordqr: jse.encrypt(this.regFrom.conpasval),
              code: this.regFrom.verificationval,
            };
            userReg.userAdd(emailval).then((res) => {
              this.$message.success(this.$t('lang.loginwassuccessful'));
              let that = this;
              setTimeout(() => {
                that.$router.push("userlogin");
              }, 1000);
            });
          }
        } else {
          return false;
        }
      });
    },
    login() {
      console.log(1111);
    },
	//发送验证码 
    sendmsg() {
		let emailval = {
		  email: this.regFrom.useremail,
		};
		if(!this.regFrom.useremail){
			return false
		}
		userReg.emailApi(emailval).then((res) => {
				this.isRegister = res.data
				if(this.isRegister){
					if(this.dataId){
						this.inpus=false
						this.regFrom.conpasval=''
					}else{
						this.$message.error(this.$t('lang.Thisaccounthasbeen'))
						return false
					}
				}
				if (isemail(this.regFrom.useremail) && !this.timer) {
					let code = 1;
					if(this.dataId){
						code = 3
					}
					let sendval = {
						email: this.regFrom.useremail,
						code: code,
					};
					userReg.sendMail(sendval).then((res) => {
						let sk=res.msg
						if(res.msg){
							let wq=sk.split('-')
							if(sessionStorage.getItem('language')&&sessionStorage.getItem('language')=='zhong'){
								sk=wq[0]
							}else{
								sk=wq[1]
							}
							this.$message.success(sk);
							if(wq[0].indexOf('操作过于频繁')!=0){
								this.getCode();
							}
						}
					});
				}
				if (isMobile(this.regFrom.useremail) && !this.timer) {
					let sendval = {
						phone: this.regFrom.useremail,
						code: 4,
					};
					if(this.isRegister){
						sendval.code=2
					}
					userReg.sendPhoneCode(sendval).then((res) => {
						let sk=res.msg
						if(res.msg){
							let wq=sk.split('-')
							if(sessionStorage.getItem('language')&&sessionStorage.getItem('language')=='zhong'){
								sk=wq[0]
							}else{
								sk=wq[1]
							}
							this.$message.success(sk);
							if(wq[0].indexOf('操作过于频繁')!=0){
								this.getCode();
							}
						}
					});
				}
			});
    },
		//显示隐藏密码
		handleShowPwd(ind) {
		  if(ind==1){
				this.pwdInputType = this.pwdInputType === "password" ? "text" : "password";
				this.eyeIcon =
				  this.eyeIcon === require("@/assets/imgs/close_eyes.png")
				    ? require("@/assets/imgs/kejian.png")
				    : require("@/assets/imgs/close_eyes.png");
			}else{
				this.pwdInputTypes = this.pwdInputTypes === "password" ? "text" : "password";
				this.eyeIcons =
				  this.eyeIcons === require("@/assets/imgs/close_eyes.png")
				    ? require("@/assets/imgs/kejian.png")
				    : require("@/assets/imgs/close_eyes.png");
			}
		},
    getCode() {
      /* 倒计时 */
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.msgtime = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.msgtime = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
  },
};
</script>

<style lang="less" scoped>
// /deep/.main .loginPanel .title{
//   display: none !important;
// }
.box-card {
  /* 卡片 */
  width: 6.16rem;

  box-sizing: border-box;
  margin-right: 12%;
  position: relative;
  // .userreg {
  //   // padding-top: 76px;
  // }
  // .weixinreg {
  //   // padding-top: 44px;
  // }
}
.title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  p.usertitle {
    font-size: 0.24rem;
    font-family: PingFang-SC-Bold;
    font-weight: bold;
    color: #424242;
  }
  .line {
    /* 线 */
    width: 0.58rem;
    height: 2px;
    background: #131715;
    margin-top: 13px;
    margin-bottom: 0.44rem;
  }
  .lines {
    /* 线二 */
    width: 0.58rem;
    height: 2px;
    background: #131715;
    margin-top: 13px;
    margin-bottom: 0.3rem;
  }
  img.erweima {
    /* 二维码 */
    width: 0.83rem;
    height: 0.84rem;
    position: absolute;
    z-index: 10;
    top: -0.66rem;
    right: -0.69rem;
  }
  div.dlbj {
    /* 扫码注册 */
    //width: 1.02rem;
    height: 0.3rem;
    padding-right: 5px;
		padding-right: .2rem;
		padding-left: .1rem;
		box-sizing: border-box;
    background: #307b37;
    opacity: 0.9;
    border-radius: 2px;
    background: url("../../../assets/imgs/dlbj.png") no-repeat;
		background-size: 100% 100%;
    position: absolute;
    z-index: 10;
    top: -0.64rem;
    right: 0.24rem;
    p {
      font-size: 0.16rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #44944c;
      text-align: center;
      line-height: 0.3rem;
    }
  }
}
/* 已有账号 */
.haveuser {
  margin-top: 4px;
}
.wechatuser {
  margin-top: -3px;
}
.existuser {
  margin-top: -3px;
  font-size: 14px;
  color: #b2b2b2;
  font-weight: 500;
}
a {
  margin-top: 3px;
  font-size: 14px;
  font-family: PingFang-SC-Bold;
  font-weight: 500;
  color: #6eb92b;
}
.backlogins {
  .button {
    width: 100%;
    height: 0.6rem;
    outline-style: none;
    border: none;
    background: linear-gradient(0deg, #333232, #131715);
    border-radius: 6px;
    text-align: center;
    font-size: 0.24rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffffff;
    line-height: 0.6rem;
    margin-top: 0.2rem;
  }
}
/* 微信注册问题 */
.weixinreg {
  .wechat {
    // width: 1.95rem;
    // height: 1.95rem;
    // background: #ffffff;
    // border: 1px solid #b2b2b2;
    // border-radius: 2px;
    // font-size: 0.16rem;
    // margin: 0 auto;
    // margin-bottom: 0.2rem;
  }
  .inputcontentWechat {
    width: 100%;
    height: 0.62rem;
    margin-bottom: 0.2rem;
    position: relative;
    display: flex;
    input {
      outline-style: none;
      width: 100%;
      height: 0.58rem;
      text-decoration: none;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      text-indent: 0.51rem;
      font-size: 0.16rem;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 0.6rem;
    }

    img {
      width: 0.18rem;
      height: 0.2rem;
      position: absolute;
      left: 0.23rem;
      top: 0.2rem;
      z-index: 1;
    }

    .emailimg {
      width: 0.18rem;
      height: 0.14rem;
      left: 0.22rem;
      top: 0.24rem;
    }
  }
  // .backlogin {
  //   // margin-top: .42rem;
  // }
}
/* 验证码发送 */
.sendmsg {
  width: 1.16rem;
  height: 0.5rem;
  background: #3e3f3e;
  border-radius: 6px;
  position: absolute;
  cursor: pointer;
  right: 0.07rem;
  top: 0.04rem;
  z-index: 10;
  p {
    text-align: center;
    font-size: 0.16rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.5rem;
  }
}

/deep/.el-input .el-input__inner {
  width: 100%;
  color: #333 !important;
}
.inputcontent{
	.eyes-icon {
	  width: 0.26rem;
	  height: 0.18rem;
	  position: absolute;
	  top: 0.2rem;
	  left: 90%;
	  right: 0.2rem !important;
	}
}
</style>
